import React, {useEffect} from "react";
import "./CSS/Home.css"
import Navbar from "../../Components/Navbar/Navbar";
import HomeFirstC from "../../Components/HomeFirstC/HomeFirstC";
import Footer from "../../Components/Footer/Footer";
import Contacts from "../../Components/Contacts/Contacts";

import LightBox from "../../Components/LightBox/LightBox";

import Ticket_CARD from "../../Components/Ticket_CARD/Ticket_CARD";

import img1 from "./MEDIA/img1.webp"
import img2 from "./MEDIA/img2.webp"
import img3 from "./MEDIA/img3.webp"

import saf from "./MEDIA/saf.jpg"
import ser from "./MEDIA/ser.jpeg"
// import sas from "./MEDIA/sas.jpg"
import sas from "./MEDIA/sas2.jpg"
import allop from "./MEDIA/allop.jpeg"


import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import sec_paym from "./MEDIA/valid.png"
import truck from "./MEDIA/truck.png"
import ticket from "./MEDIA/ticket.png"
import guide from "./MEDIA/guide.png"
import kite from "./MEDIA/kitesurfing.png"

const INP_card = (props) => {
    return(
        <div className="Home__inp_card_div">
            <img className="Home__inp_card_img" src={props.img} alt="img" />
            <p className="Home__inp_par">
                {props.tit}
            </p>
            <br />
            <p className="Home__inp_par_2">
                {props.par}
            </p>
        </div>
    )
}

const PRES_card = (props) => {

    let navigate = useNavigate()

    return(
        <div className="Home__pres__card_div">
            <div className="Home__pres__card_img_div">
                <img src={props.img} alt="img" className="Home__pres__card_img" />
            </div>
            <div className="Home__pres_par_div">
                <h1 className="Home__pres_title">
                    {props.title}
                </h1>
                <p className="Home__pres_par">
                    {props.par}
                </p>
                <div className="Home__pres_button_div">
                    <button className="Home__pres_button" onClick={() => navigate(props.nav)} >
                        {props.but}
                    </button>
                </div>
            </div>
        </div>
    )
}

const Home = () => {

    const lang_index = useSelector(state => state.langReducer)
    const home_texts = require("./JSON/home_texts.json")

    const safari_texts = require("../Safari/JSON/safari_texts.json")

    let navigate = useNavigate()

    function removeElementsByClass2(){
        const elements = document.getElementsByClassName("blog js-powrMark pad-m pad-top-l powrMark text-center");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
        alert("Delete");
    }

    const images = [
        [
            {image_path:"MEDIA/HOME/img1.jpg", index:0},  
        ],
        [
            {image_path:"MEDIA/HOME/img2.jpg", index:1},
            {image_path:"MEDIA/HOME/img3.jpg", index:2},
            {image_path:"MEDIA/HOME/img4.jpg", index:3}
        ]
    ]

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
        <HomeFirstC>
            <Navbar/>
        </HomeFirstC>
        <div className="Home__sis_main_div">
            <h1 className="Home__sis_title">
                {home_texts[lang_index.lang].sis.title}
            </h1>
            <div className="Home__sis_second_div">
                <div className="Home__sis_button_card" onClick={() => navigate("safari")}>
                    <img src={saf} alt="safari" className="Home__sis_button_img" />
                    <div className="Home__sis_card_abs">
                        <p className="Home__sis_card_abs_title">Safari</p>
                    </div>
                </div>
                <div className="Home__sis_button_card" onClick={() => navigate("escursioni")}>
                    <img src={sas} alt="safari" className="Home__sis_button_img" />
                    <div className="Home__sis_card_abs">
                        <p className="Home__sis_card_abs_title">{home_texts[lang_index.lang].sis.fof}</p>
                    </div>
                </div>
                <div className="Home__sis_button_card" onClick={() => navigate("alloggi")}>
                    <img src={allop} alt="safari" className="Home__sis_button_img" />
                    <div className="Home__sis_card_abs">
                        <p className="Home__sis_card_abs_title">{home_texts[lang_index.lang].sis.allop}</p>
                    </div>
                </div>
                <div className="Home__sis_button_card" onClick={() => navigate("ristorante")}>
                    <img src={ser} alt="services" className="Home__sis_button_img" />
                    <div className="Home__sis_card_abs">
                        <p className="Home__sis_card_abs_title">{home_texts[lang_index.lang].sis.second_card}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="Home__inp_main_div">
            <h1 className="Home__titles">{home_texts[lang_index.lang].plus.title}</h1>
            <div className="Home__grid_inp_cards">
                <INP_card img = {sec_paym} tit = {home_texts[lang_index.lang].plus.card_1.title} par = {home_texts[lang_index.lang].plus.card_1.par} />
                <INP_card img = {truck} tit = {home_texts[lang_index.lang].plus.card_2.title} par = {home_texts[lang_index.lang].plus.card_2.par}/>
                <INP_card img = {ticket} tit = {home_texts[lang_index.lang].plus.card_3.title} par = {home_texts[lang_index.lang].plus.card_3.par} />
                <INP_card img = {guide} tit = {home_texts[lang_index.lang].plus.card_4.title} par = {home_texts[lang_index.lang].plus.card_4.par} />
                <INP_card img = {kite} tit = {home_texts[lang_index.lang].plus.card_5.title} par = {home_texts[lang_index.lang].plus.card_5.par} />
            </div>
        </div>
        <div className="Home__ins_main_div">
            <h1 className="Home__ins_title">
                JJC Animation
            </h1>
            <p className="Safari__par">
                {home_texts[lang_index.lang].newwithimgs.par}
            </p>
            <p className="Safari__par">
                {home_texts[lang_index.lang].newwithimgs.contacts} +254 (0)705 245514  John Baya JB
            </p>
            <LightBox images = {images} />
        </div>
        <div className="Home__ins_main_div">
            <h1 className="Home__ins_title">
                {home_texts[lang_index.lang].ins.title}
            </h1>
            <div className="Home__ins_second_div">
                {/* <Ticket_CARD index = {3} img = "MEDIA/Tickets/Safari/img_3.jpg" title = "SAFARI MARATHON" par = {safari_texts[lang_index.lang].cards.card_4} price = "€50.00" page = "safari" />
                <Ticket_CARD index = {4} img = "MEDIA/Tickets/Safari/img_4.jpg" title = "SAVANA" par = {safari_texts[lang_index.lang].cards.card_5} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {5} img = "MEDIA/Tickets/Safari/img_5.jpg" title = "SAFARI TSAVO EAST AMBOSELI" par = {safari_texts[lang_index.lang].cards.card_6} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {1} img = "MEDIA/Tickets/Safari/img_1.jpg" title = "MOMBASA TAITA HILLS" par = {safari_texts[lang_index.lang].cards.card_2} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {2} img = "MEDIA/Tickets/Safari/img_2.jpg" title = "MASAI MARA" par = {safari_texts[lang_index.lang].cards.card_3} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {0} img = "MEDIA/Tickets/Safari/img_0.jpg" title = "MOMBASA TAITA AMBOSELI" par = {safari_texts[lang_index.lang].cards.card_1} price = "€50.00" page = "safari" /> */}
                {/* <Ticket_CARD index = {3} img = "MEDIA/Tickets/Safari/img_3.jpg" title = "SAFARI MARATHON (TSAVO EAST)" par = {safari_texts[lang_index.lang].cards.card_4} price = "€50.00" page = "safari" />
                <Ticket_CARD index = {4} img = "MEDIA/Tickets/Safari/img_4.jpg" title = "SAVANA" par = {safari_texts[lang_index.lang].cards.card_5} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {9} img = "MEDIA/Tickets/Safari/img_9.jpg" title = "MASAI MARA BY FLIGHT" par = {safari_texts[lang_index.lang].cards.card_10} price = "€50.00" page = "safari" />
                <Ticket_CARD index = {5} img = "MEDIA/Tickets/Safari/img_5.jpg" title = "SAFARI TSAVO EAST AMBOSELI" par = {safari_texts[lang_index.lang].cards.card_6} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {1} img = "MEDIA/Tickets/Safari/img_1.jpg" title = "MOMBASA TAITA HILLS" par = {safari_texts[lang_index.lang].cards.card_2} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {11} img = "MEDIA/Tickets/Safari/img_11.jpg" title = "MASAI MARA BY FLIGHT MALINDI" par = {safari_texts[lang_index.lang].cards.card_12} price = "€50.00" page = "safari" /> */}
                <Ticket_CARD index = {3} img = "MEDIA/Tickets/Safari/img_3.jpg" title = "SAFARI MARATHON (TSAVO EAST)" par = {safari_texts[lang_index.lang].cards.card_4} price = "€50.00" page = "safari" />
                <Ticket_CARD index = {4} img = "MEDIA/Tickets/Safari/img_4.jpg" title = "SAVANA (TSAVO EAST)" par = {safari_texts[lang_index.lang].cards.card_5} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {9} img = "MEDIA/Tickets/Safari/img_9.jpg" title = "MASAI MARA BY FLIGHT" par = {safari_texts[lang_index.lang].cards.card_10} price = "€50.00" page = "safari" />
                <Ticket_CARD index = {5} img = "MEDIA/Tickets/Safari/img_5.jpg" title = "SAFARI TSAVO EAST AMBOSELI" par = {safari_texts[lang_index.lang].cards.card_6} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {1} img = "MEDIA/Tickets/Safari/img_1.jpg" title = "MOMBASA TAITA HILLS TSAVO EAST" par = {safari_texts[lang_index.lang].cards.card_2} price = "€50.00" page = "safari"/>
                <Ticket_CARD index = {11} img = "MEDIA/Tickets/Safari/img_11.jpg" title = "MASAI MARA BY FLIGHT MALINDI" par = {safari_texts[lang_index.lang].cards.card_12} price = "€50.00" page = "safari" />
            </div>
        </div>
        <div className="Home__ins_main_div">
            <h1 className="Home__ins_title">
                {home_texts[lang_index.lang].ins.title2}
            </h1>
            <div className="Home__ins_second_div">
                <Ticket_CARD index = {0} img = "MEDIA/Tickets/Escursioni/img_0.jpg" title = "ESCURSIONE A MARAFA HELL’S KITCHEN" par = "" price = "€20.00" page = "escursioni" />
                {/* <Ticket_CARD index = {1} img = "MEDIA/Tickets/Escursioni/img_1.jpg" title = "ESCURSIONE ALL’ISOLA DELL’AMORE" par = "" price = "€20.00" page = "escursioni" /> */}
                <Ticket_CARD index = {2} img = "MEDIA/Tickets/Escursioni/img_2.jpg" title = "APERITIVO AL TRAMONTO ALLE MANGROVIE" par = "" price = "€20.00" page = "escursioni" />
                <Ticket_CARD index = {3} img = "MEDIA/Tickets/Escursioni/img_3.jpg" title = "ESCURSIONE ALLE ROVINE DI GEDE" par = "" price = "€20.00" page = "escursioni" />
                <Ticket_CARD index = {4} img = "MEDIA/Tickets/Escursioni/img_4.jpg" title = "ESCURSIONE ISOLA DI ROBINSON E SPIAGGIA DORATA" par = "" price = "€20.00" page = "escursioni" />
                <Ticket_CARD index = {5} img = "MEDIA/Tickets/Escursioni/img_5.jpg" title = "SAFARI BLU A MIDA CREEK" par = "" price = "€20.00" page = "escursioni" />
                <Ticket_CARD index = {8} img = "MEDIA/Tickets/Escursioni/img_8.jpg" title = "MALINDI E I SUOI ARTIGIANI" par = "" price = "€20.00" page = "escursioni" />
            </div>
        </div>
        <div className="Home__pres__main_div">
            <PRES_card nav = "faq" img = {img1} title = {home_texts[lang_index.lang].info_cards.card_1.title} but = {home_texts[lang_index.lang].info_cards.card_1.but} par = {home_texts[lang_index.lang].info_cards.card_1.par} />
            <PRES_card nav = "chi_siamo" img = {img2} title = {home_texts[lang_index.lang].info_cards.card_2.title} but = {home_texts[lang_index.lang].info_cards.card_2.but} par = {home_texts[lang_index.lang].info_cards.card_2.par} />
            <PRES_card nav = "safari" img = {img3} title = {home_texts[lang_index.lang].info_cards.card_3.title} but = {home_texts[lang_index.lang].info_cards.card_3.but} par = {home_texts[lang_index.lang].info_cards.card_3.par} />
        </div>
        <div className="row w-100 py-5 bg-dark Home__ig__main_div">
            <h2 className="text-center text-white">Follow Us On Instagram</h2>
            <div className="powr-instagram-feed" id="4703cbec_1689933049">
               
            </div>
            
        </div>
        <Contacts/>
        <Footer/>
        </>
    )
}

export default Home;