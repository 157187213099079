import React, {useEffect} from "react";
import "./CSS/Info.css"
import NavWrapper from "../../Components/NavWrapper/NavWrapper";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Accordion from 'react-bootstrap/Accordion';

import { useSelector } from "react-redux";

const AccItem = (props) => {
    return(
        <Accordion.Item eventKey={props.akey}>
            <Accordion.Header>{props.title}</Accordion.Header>
            <Accordion.Body>
                {props.body.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </Accordion.Body>
        </Accordion.Item>
    )
}

const CosPoCard = (props) => {
    return(
        <div className="Info_cos_po_card">
            <h1 className="Info_cos_pos_card_title">
                {props.title}
            </h1>
            <p className="Info_cos_pos_card_par">
                {props.par}
            </p>
        </div>
    )
}

const Info = () => {

    const lang_index = useSelector(state => state.langReducer)
    const info_texts = require("./JSON/info_texts.json")

    useEffect(() => {
        document.querySelector(".Navbar__main_div").scrollIntoView({ behavior: 'smooth' })
    }, [])

    return(
        <>
            <NavWrapper title = "FAQ" image = "MEDIA/info.webp">
                <Navbar/>
            </NavWrapper>
            <div className="Info__infos_main_div">
                <h1 className="Info__infos_title">
                    {info_texts[lang_index.lang].sec_1.title}
                </h1>
                <div className="Info__infos_second_div">
                    <Accordion>
                        <AccItem akey = "0" title = {info_texts[lang_index.lang].sec_1.ars.ar_1.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_1.par} /> 
                        <AccItem akey = "1" title = {info_texts[lang_index.lang].sec_1.ars.ar_2.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_2.par} /> 
                        <AccItem akey = "2" title = {info_texts[lang_index.lang].sec_1.ars.ar_3.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_3.par} /> 
                        <AccItem akey = "3" title = {info_texts[lang_index.lang].sec_1.ars.ar_4.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_4.par} /> 
                        <AccItem akey = "4" title = {info_texts[lang_index.lang].sec_1.ars.ar_5.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_5.par} /> 
                        <AccItem akey = "5" title = {info_texts[lang_index.lang].sec_1.ars.ar_6.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_6.par} /> 
                        <AccItem akey = "6" title = {info_texts[lang_index.lang].sec_1.ars.ar_7.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_7.par} /> 
                        <AccItem akey = "7" title = {info_texts[lang_index.lang].sec_1.ars.ar_8.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_8.par} /> 
                        <AccItem akey = "8" title = {info_texts[lang_index.lang].sec_1.ars.ar_9.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_9.par} /> 
                        <AccItem akey = "9" title = {info_texts[lang_index.lang].sec_1.ars.ar_10.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_10.par} /> 
                        <AccItem akey = "10" title = {info_texts[lang_index.lang].sec_1.ars.ar_11.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_11.par} /> 
                        <AccItem akey = "11" title = {info_texts[lang_index.lang].sec_1.ars.ar_12.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_12.par} /> 
                        <AccItem akey = "12" title = {info_texts[lang_index.lang].sec_1.ars.ar_13.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_13.par} /> 
                        <AccItem akey = "13" title = {info_texts[lang_index.lang].sec_1.ars.ar_14.title} body = {info_texts[lang_index.lang].sec_1.ars.ar_14.par} /> 
                    </Accordion>
                </div>
            </div>
            <div className="Info__cos_po_main_div">
                <div className="Info__cos_po_title_div">
                    <h1 className="Info__cos_po_title">
                        {info_texts[lang_index.lang].sec_2.title}
                    </h1>
                    {/* <p className="Info__cos_po_par">
                        {info_texts[lang_index.lang].sec_2.par}
                    </p> */}
                </div>
                <div className="Info__cos_po_second_div">
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_1.title} par = {info_texts[lang_index.lang].sec_2.cards.card_1.par} />
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_2.title} par = {info_texts[lang_index.lang].sec_2.cards.card_2.par} />
                    {/* <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_3.title} par = {info_texts[lang_index.lang].sec_2.cards.card_3.par} /> */}
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_4.title} par = {info_texts[lang_index.lang].sec_2.cards.card_4.par} /> 
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_5.title} par = {info_texts[lang_index.lang].sec_2.cards.card_5.par} />
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_6.title} par = {info_texts[lang_index.lang].sec_2.cards.card_6.par} />
                    {/* <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_7.title} par = {info_texts[lang_index.lang].sec_2.cards.card_7.par} /> */}
                    <CosPoCard title = {info_texts[lang_index.lang].sec_2.cards.card_8.title} par = {info_texts[lang_index.lang].sec_2.cards.card_8.par} />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Info;